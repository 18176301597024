export const stringsHelp = {
  firstLetterUpper,
  avatar,
  formatPhoneNumber,
  formatPhoneNumbers
};

function firstLetterUpper(string, eachWord) {
  string = string.toLowerCase();
  if (eachWord) {
    return string.split(' ').map(word => firstLetterUpper(word)).join(' ');
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function avatar(string) {
  string = string.toUpperCase().split(' ');

  let firstLetter = string[0].charAt(0);
  let secondLetter = string.length > 1 ? string.slice(-1).pop().charAt(0) : string[0].charAt(1);

  return firstLetter + secondLetter;
}

function formatPhoneNumbers(phone) {
  let phones = [];
  if(!phone) {
    return '';
  }

  phone = phone.replace(/\/\//g, '/').split('/');
  phone.forEach(v => {
    v = formatPhoneNumber(v);
    if (v.length > 0) {
      phones.push(v);
    }
  });

  return phones;
}

function formatPhoneNumber(phone) {
  phone = phone.replace(/[^\d]+/g, '');

  if(phone.length === 10 || phone.length === 11){
    return '(' + phone.substr(0, 2) + ') '  + phone.substr(2, phone.length-6) + '-' + phone.substr(phone.length-4, 4);
  } else if (phone.length > 7) {
    return phone.substr(0, phone.length-4) + '-' +phone.substr(phone.length-4, 4);
  } else if (phone.length > 4) {
    return phone.substr(0, 4) + '-' + phone.substr(4);
  }

  return phone;
}