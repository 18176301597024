import React, {Component} from 'react';
import {CancelToken} from 'axios';
import {AutoComplete, Icon, Input, Skeleton} from "antd";
import {Link, withRouter} from "react-router-dom";
import {pathRoutes} from "../routes";
import {connect} from "react-redux";
import {patientActions} from "../actions";
import {stringsHelp} from "../helpers";

class AutocompletePatient extends Component {

	state = {
    searchingPatients: false,
    selectedPatient: {}
  };

  cancelSource = CancelToken.source();

  searchPatient = (value) => {
    const {dispatch} = this.props;
    this.value = value;

    if (this.searchingPatientsValue !== value){

      if(this.state.searchingPatients) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.setState({searchingPatients: true});
      this.searchingPatientsValue = value;
      this.select('', {props: {children: value}});

      dispatch(patientActions.getPatients(1, value, this.cancelSource.token))
        .then(() => {
          this.setState({searchingPatients: false});
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

	componentDidMount() {
    let {selectedPatient} = this.props;

    if(selectedPatient) {
	    this.listPatients.push(selectedPatient);
	
	    this.select(selectedPatient.id);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.selectedPatient && !prevProps.selectedPatient)
      || (prevProps.selectedPatient && prevProps.selectedPatient.id !== this.props.selectedPatient.id)) {
      this.listPatients.push(this.props.selectedPatient);
      this.select( this.props.selectedPatient.id);
    }
  }
	
	select = (value, option) => {
    if(value === 'newPatient') {
      this.props.onNewPatient(this.value);
    } else {
      this.value = option ? option.props.children : '';
      if (this.props.onChange) {
        this.props.onChange(value);
      }
      if (this.props.onSelectPatient) {
        let patient = this.listPatients.filter(p => p.id === parseInt(value)).pop();
        
        this.setState({selectedPatient: patient ? patient : {}});
        if(patient) {
	        this.props.onSelectPatient(patient);
        }
      }
    }
  };

  render() {
    const {patient} = this.props;
    let {selectedPatient} = this.state;

    let autocompleteSource = [];
    let value = this.value;
    this.listPatients = [];
	
	  if(selectedPatient.id) {
      this.listPatients = [selectedPatient];
      autocompleteSource.push((
        <AutoComplete.OptGroup
          key={1}
          label={''}
        >
          <AutoComplete.Option key={selectedPatient.id} value={`${selectedPatient.id}`}>{stringsHelp.firstLetterUpper(selectedPatient.name, true) + (selectedPatient.cpf ? ' | ' + selectedPatient.cpf : '')}</AutoComplete.Option>
        </AutoComplete.OptGroup>
      ));
      value = stringsHelp.firstLetterUpper(selectedPatient.name, true) + (selectedPatient.cpf ? ' | ' + selectedPatient.cpf : '');
    } else if (patient.items) {
      this.listPatients = patient.items.toArray();
      autocompleteSource.push((
        <AutoComplete.OptGroup
          key={1}
          label={''}
        >
          {patient.items.map(value => (<AutoComplete.Option key={value.id} value={`${value.id}`}>{stringsHelp.firstLetterUpper(value.name, true) + (value.cpf ? ' | ' + value.cpf : '')}</AutoComplete.Option>))}
        </AutoComplete.OptGroup>
      ))
    }
    
    if (this.state.searchingPatients) {
	    autocompleteSource.push((
		    <AutoComplete.OptGroup
			    key={'loading'}
			    label={''}
          disabled
		    >
          <AutoComplete.Option key='loading1' value='loading1' disabled><Skeleton loading active paragraph={false}>Carregando.</Skeleton></AutoComplete.Option>
          <AutoComplete.Option key='loading2' value='loading2' disabled><Skeleton loading active paragraph={false}>Carregando.</Skeleton></AutoComplete.Option>
          <AutoComplete.Option key='loading3' value='loading3' disabled><Skeleton loading active paragraph={false}>Carregando.</Skeleton></AutoComplete.Option>
		    </AutoComplete.OptGroup>
	    ));
    }
	  let suffix = this.state.searchingPatients ? <Icon type="loading" style={{ color: "rgba(0,0,0,.25)" }}/> : false;
	
	  autocompleteSource.push((
		  <AutoComplete.OptGroup disabled key={'newPatient'}>
			  <AutoComplete.Option key={'newPatient'}>
				  <Link to={pathRoutes.newPatient}>
					  + Adicionar novo paciente
				  </Link>
			  </AutoComplete.Option>
		  </AutoComplete.OptGroup>));
    
    return (
      <AutoComplete
        dataSource={autocompleteSource}
        filterOption={false}
        autoFocus={true}
        onSearch={this.searchPatient}
        defaultActiveFirstOption={false}
        dropdownClassName="autocomplete-patient"
        onSelect={this.select}
        value={value}
        allowClear={!!selectedPatient.id}>
        <Input suffix={suffix} placeholder='Digite o nome ou CPF para pesquisar...'/>
      </AutoComplete>
    );
  }
}

function mapStateToProps(state) {
  const {patient} = state;
  return {
    patient
  };
}

const connected = withRouter(connect(mapStateToProps)(AutocompletePatient));
export default connected;
