import { schedulingConstants } from '../constants';
import {List} from "immutable";
import moment from "moment";

export function scheduling(state = {}, action) {
  switch (action.type) {
    case schedulingConstants.GETSCHEDULING_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        data: new List()
      });
    case schedulingConstants.GETSCHEDULING_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data
      });
    case schedulingConstants.GETSCHEDULING_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case schedulingConstants.GETPROCEDURE_REQUEST:
      return Object.assign({}, state, {
        procedure: {
          loading: true,
          data: {}
        }
      });
    case schedulingConstants.GETPROCEDURE_SUCCESS:
      return Object.assign({}, state, {
        procedure: {
          loading: false,
          data: action.data
        }
      });
    case schedulingConstants.GETPROCEDURE_FAILURE:
      return Object.assign({}, state, {
        procedure: {
          loading: false,
          error: action.error
        }
      });
    case schedulingConstants.SETDATE:
      return Object.assign({}, state, {
        date: moment(action.date)
      });
    default:
      return state
  }
}
