import {withRouter} from "react-router-dom";
import React, {Component} from "react";
import {DatePicker} from "antd";
import {connect} from "react-redux";
import moment from 'moment';
import {schedulingActions} from "../actions";

const dateFormat = 'DD/MM';


class FilterDate extends Component {

  state = {
    date: moment().startOf('day'),
  };

  componentDidMount() {
    const {scheduling} = this.props;
    let storageDate = moment(localStorage.getItem('filter-date'));

    if(scheduling.date){
      this.setState({date: scheduling.date});
    } else if (storageDate.isValid()) {
      this.changeDate(storageDate);
    } else {
      this.changeDate(this.state.date);
    }
  }

  nextDay = () => {
    let date = this.state.date.add(1, 'day').startOf('day');

    this.changeDate(date)
  };

  previousDay = () => {
    let date = this.state.date.subtract(1, 'day').startOf('day');

    this.changeDate(date)
  };

  changeDate = (date) => {
    const {dispatch} = this.props;

    this.setState({date});
    dispatch(schedulingActions.setDate(date));
  };


  checkDay = () => {
    let diff = this.state.date.diff(moment().startOf('day'), 'day');

    switch (diff) {
      case 0:
        return 'Hoje, ';
      case 1:
        return 'Amanhã, ';
      case -1:
        return 'Ontem, ';
      default:
        return this.state.date.format('ddd') + ', ';
    }
  };

  clickInput = () => {
    let open = !this.inputDate.picker.state.open;

    this.inputDate.picker.setState({open});
  };

  render() {
    let location = this.props.location.pathname;

    if (location !== '/' && location !== '/agendamentos' ) {
      return null;
    }

    return (
      <div className="map-date">
        <div>
          <i className="icon-arrow-left" onClick={this.previousDay}/>
          <span onClick={this.clickInput}>{this.checkDay()}{this.state.date.format(dateFormat)}</span>
          <i className="icon-arrow-right" onClick={this.nextDay}/>
        </div>
        <DatePicker style={{
          position: 'absolute',
          clip: 'rect(0, 0, 0, 0)'
        }} defaultValue={moment()} format={dateFormat}
                    ref={input => this.inputDate = input} onChange={this.changeDate} value={this.state.date}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {scheduling} = state;
  return {
    scheduling
  };
}

const connected = withRouter(connect(mapStateToProps)(FilterDate));
export default connected;

