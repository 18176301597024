import React, {Component} from 'react';
import {Checkbox, Input, InputNumber} from "antd";
import {CancelToken} from "axios";
import Modal from "./Modal";
import {stringsHelp} from "../helpers";
import {connect} from "react-redux";
import {selectsActions} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import {List} from "immutable";


class SelectSurgery extends Component {

  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),

    tracks: new List(),
    hasMoreItems: true
  };

  searchingProcedures = false;
  page = 0;

  loadSurgicalProceduresAll = () => {
    const {dispatch} = this.props;

    dispatch(selectsActions.getSurgicalProceduresMostUsed(this.props.medical_plan_table_id));
    if(!this.searchingProcedures) {
      this.loadSurgicalProcedures(1)
    }
  };

  loadSurgicalProcedures = (page) => {
    let value = this.filter ? this.filter.input.value : '';
    const {dispatch} = this.props;

    if (this.searchingProcedureValue !== value || this.page !== page) {
      this.page = page;

      if(this.searchingProcedures && this.searchingProcedureValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingProcedures = true;
      this.searchingProcedureValue = value;

      dispatch(selectsActions.getSurgicalProcedures(this.props.medical_plan_table_id, page, value, this.cancelSource.token))
        .then((surgical_procedures) => {
          this.searchingProcedures = false;

          if(surgical_procedures.error){
            return null;
          }
          let tracks;
          if(surgical_procedures.page > 1) {
            tracks = this.state.tracks.concat(surgical_procedures.result);
          } else {
            tracks = new List(surgical_procedures.result)
          }
          return this.setState({
            tracks,
            hasMoreItems: surgical_procedures.page < surgical_procedures.pages
          });
        });
    }
  };

  loadItems = () => {
    let page = this.page;
    page++;
    this.loadSurgicalProcedures(page);
  };

  setModalVisible(modalVisible) {
    this.setState({modalVisible});
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.changeSelected(new List(this.props.value));
    }

    if (this.props.medical_plan_table_id !== prevProps.medical_plan_table_id) {
      this.changeSelected(new List());
      this.loadSurgicalProceduresAll();
    }
  }

  filterProcedures = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true
    });

    this.loadSurgicalProcedures(1);
  };

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadSurgicalProceduresAll();
  }

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({selected});
  };

  selectSurgicalProcedure = (event) => {
    let value = event.target.value;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselectSurgicalProcedure = (event) => {
    let value = event.target.value;
    let selected = this.state.selected.filter(v => v.id !== value.id);

    this.changeSelected(selected);
  };

  changeLaterality = (value, surgical_procedure) => {
    let selectedIndex = this.state.selected.findIndex(v => v.id === surgical_procedure.id);

    surgical_procedure.laterality = value;

    let selected = this.state.selected.set(selectedIndex, surgical_procedure);

    this.changeSelected(selected);
  };

  render() {
    const {surgical_procedures, surgical_procedures_most_used} = this.props;
    const show_most_used = (this.filter && this.filter.input.value.length) || !surgical_procedures_most_used || surgical_procedures_most_used.data.length !== 0;

    if(!this.props.medical_plan_table_id) {
      return 'Selecione um convênio'
    }

    return (
      <div className='form-list'>
        <Input hidden={true}/>
        {
          this.state.selected.size ?
            this.state.selected.map(surgical_procedure => {
              let name = stringsHelp.firstLetterUpper(surgical_procedure.name);
              return (
                <div className='form-list-item' title={name} key={surgical_procedure.id}>
                  <span className='laterality'>{surgical_procedure.laterality}X</span>
                  <span className='form-list-description'>{surgical_procedure.code} - {name}</span>
                  <span className='form-list-del' onClick={() => this.deselectSurgicalProcedure({target: {value: surgical_procedure}})}>
                    <i className='icon-close'/>
                  </span>
                </div>
              )})
            : null
        }
        <div className='form-list-item'>
          <button type='button' className='form-add-link' onClick={() => this.setModalVisible(true)}>+ Adicionar
            cirurgias
          </button>
        </div>

        <Modal
          title='Procedimentos'
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText={'SALVAR'}
          buttonOkType={'primary'}
        >
          <Input placeholder='Buscar' ref={input => this.filter = input} onKeyUp={this.filterProcedures}/>

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden' }`} style={{marginTop: '21px'}}>
            <div className='form-list-title'>SELECIONADOS ({this.state.selected.size})</div>
            {
              this.state.selected.size ?
                this.state.selected.map(surgical_procedure => {
                  let name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                  return (
                    <div className='form-list-item slide-in' key={surgical_procedure.id}>
                      <span className='form-list-description' title={name}>
                          <Checkbox defaultChecked={true} value={surgical_procedure} onChange={this.deselectSurgicalProcedure} style={{width: 'calc(100% - 63px)'}}>
                            {surgical_procedure.code} - {name}
                          </Checkbox>
                          <InputNumber
                            min={1}
                            max={15}
                            defaultValue={1}
                            formatter={value => `${value}X`}
                            parser={value => value.replace('X', '')}
                            style={{
                              width: '63px',
                              fontSize: '17px',
                              fontWeight: '500',
                            }}
                            onChange={v => this.changeLaterality(v, surgical_procedure)}
                          />
                      </span>
                    </div>
                  )})
                : null
            }
          </div>
          <div className={`form-list ${(show_most_used) ? '' : 'hidden'}`} style={{marginTop: '21px'}}>
            <div className='form-list-title'>MAIS REALIZADOS</div>
            {
              surgical_procedures_most_used ?
                surgical_procedures_most_used.data.map(surgical_procedure => {
                  let name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                  if(this.state.selected.find(a => a.id === surgical_procedure.id)){
                    return null;
                  }

                  return (
                    <div className='form-list-item slide-in' key={surgical_procedure.id}>
                      <span className='form-list-description' title={name}>
                          <Checkbox value={surgical_procedure} onChange={this.selectSurgicalProcedure}>
                            {surgical_procedure.code} - {name}
                          </Checkbox>
                      </span>
                    </div>
                  )
                })
                : null
            }
          </div>
          <div className='form-list' style={{marginTop: '21px', height: '500px', overflow: 'auto'}}>
            <div className='form-list-title'>TODOS</div>

            {
              surgical_procedures ?
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.loadItems}
                  hasMore={this.state.hasMoreItems}
                  useWindow={false}
                >
                  {
                    this.state.tracks ?
                      this.state.tracks.map(surgical_procedure => {
                        let name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                        if(this.state.selected.find(a => a.id === surgical_procedure.id)){
                          return null;
                        }

                        return (
                          <div className='form-list-item slide-in' key={surgical_procedure.id}>
                            <span className='form-list-description' title={name} >
                                <Checkbox value={surgical_procedure} onChange={this.selectSurgicalProcedure}>
                                  {surgical_procedure.code} - {name}
                                </Checkbox>
                            </span>
                          </div>
                        )
                      })
                      : null
                  }
              </InfiniteScroll> : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {selects} = state;
  const {surgical_procedures, surgical_procedures_most_used} = selects;
  return {
    surgical_procedures,
    surgical_procedures_most_used
  };
}

const connected = connect(mapStateToProps)(SelectSurgery);
export default connected;