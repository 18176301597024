import axios from "axios";
import { getToken, logout, setToken } from "./auth";
import config from "../config";

const api = axios.create({
  baseURL: config.urlApi
});

api.interceptors.request.use(async config => {
  let token = getToken();

  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

api.interceptors.response.use(
  response => {
    if (getToken() !== response.headers.authorization) {
      setToken(response.headers.authorization); // edit new token expire 15 min
    }
    return response.data;
  },
  error => {
    if (error.hasOwnProperty("CancellationError")) return error;

    if (error.hasOwnProperty("response")) {
      if (error.response.status === 401) {
        // auto logout if 401 response returned from api
        if (error.response.data === 'user.password.invalid') {
          error.response.data = 'Usuário não autorizado, verifique suas credenciais.';
        } else {
          logout();
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
