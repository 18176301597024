export const selectsConstants = {
  GETETHNICGROUPS_REQUEST: 'GETETHNICGROUPS_REQUEST',
  GETETHNICGROUPS_SUCCESS: 'GETETHNICGROUPS_SUCCESS',
  GETETHNICGROUPS_FAILURE: 'GETETHNICGROUPS_FAILURE',
  GETSURGERYCENTERS_REQUEST: 'GETSURGERYCENTERS_REQUEST',
  GETSURGERYCENTERS_SUCCESS: 'GETSURGERYCENTERS_SUCCESS',
  GETSURGERYCENTERS_FAILURE: 'GETSURGERYCENTERS_FAILURE',
  GETSURGERYCENTERROOMS_REQUEST: 'GETSURGERYCENTERROOMS_REQUEST',
  GETSURGERYCENTERROOMS_SUCCESS: 'GETSURGERYCENTERROOMS_SUCCESS',
  GETSURGERYCENTERROOMS_FAILURE: 'GETSURGERYCENTERROOMS_FAILURE',
  GETROOMS_REQUEST: 'GETROOMS_REQUEST',
  GETROOMS_SUCCESS: 'GETROOMS_SUCCESS',
  GETROOMS_FAILURE: 'GETROOMS_FAILURE',
  GETMEDICALPLAN_REQUEST: 'GETMEDICALPLAN_REQUEST',
  GETMEDICALPLAN_SUCCESS: 'GETMEDICALPLAN_SUCCESS',
  GETMEDICALPLAN_FAILURE: 'GETMEDICALPLAN_FAILURE',
  GETSURGICALPROCEDURESMOSTUSED_REQUEST: 'GETSURGICALPROCEDURESMOSTUSED_REQUEST',
  GETSURGICALPROCEDURESMOSTUSED_SUCCESS: 'GETSURGICALPROCEDURESMOSTUSED_SUCCESS',
  GETSURGICALPROCEDURESMOSTUSED_FAILURE: 'GETSURGICALPROCEDURESMOSTUSED_FAILURE',
  GETSURGICALPROCEDURES_REQUEST: 'GETSURGICALPROCEDURES_REQUEST',
  GETSURGICALPROCEDURES_SUCCESS: 'GETSURGICALPROCEDURES_SUCCESS',
  GETSURGICALPROCEDURES_FAILURE: 'GETSURGICALPROCEDURES_FAILURE',
  GETSURGERYTYPES_REQUEST: 'GETSURGERYTYPES_REQUEST',
  GETSURGERYTYPES_SUCCESS: 'GETSURGERYTYPES_SUCCESS',
  GETSURGERYTYPES_FAILURE: 'GETSURGERYTYPES_FAILURE',
  GETSURGEONS_REQUEST: 'GETSURGEONS_REQUEST',
  GETSURGEONS_SUCCESS: 'GETSURGEONS_SUCCESS',
  GETSURGEONS_FAILURE: 'GETSURGEONS_FAILURE',
  GETANAESTHETISTS_REQUEST: 'GETANAESTHETISTS_REQUEST',
  GETANAESTHETISTS_SUCCESS: 'GETANAESTHETISTS_SUCCESS',
  GETANAESTHETISTS_FAILURE: 'GETANAESTHETISTS_FAILURE',
};