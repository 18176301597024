import {patientConstants} from '../constants';
import {patientService} from "../services";

export const patientActions = {
  getPatients,
};

function getPatients(page, filter, cancelToken) {
    return dispatch => new Promise((resolve, reject) => {
    dispatch(request());

    return patientService.getPatients(page, filter, cancelToken)
      .then(patients => {
        dispatch(success(patients.result, patients.count, patients.perPage, patients.page));
        resolve(patients);
      }, error => {
        reject(error);
        return dispatch(failure(error))
      });
  });

  function request() {
    return {type: patientConstants.GETPATIENTS_REQUEST}
  }

  function success(patients, total, perPage, page) {
    return {type: patientConstants.GETPATIENTS_SUCCESS, patients, total, perPage, page}
  }

  function failure(error) {
    return {type: patientConstants.GETPATIENTS_FAILURE, error}
  }
}
