import {authenticationService} from "../services";

export {
  header,
  response,
  getToken,
  setToken,
  logout
};

export const auth = {
  header,
  response,
  getToken,
  setToken,
  logout
};

function getToken() {
  return localStorage.getItem('auth-token');
}

function setToken(token) {
  return localStorage.setItem('auth-token', token);
}

function header() {
    // return authorization header with jwt token
    let token = localStorage.getItem('auth-token');

    if (token) {
        return { 'Authorization': token };
    } else {
        return {};
    }
}

function logout() {
  authenticationService.logout();
}

function response(response) {
  let promisse;
  let contentTupe = response.headers.get('Content-Type');
  switch (contentTupe) {
    case 'image/jpg':
    case 'image/png':
      promisse = response.arrayBuffer().then((buffer) => {

        let bytes = [].slice.call(new Uint8Array(buffer));

        let binary = '';

        bytes.forEach((b) => binary += String.fromCharCode(b));

        return `data:${contentTupe};base64,${window.btoa(binary)}`;
      });
      break;
    default:
      promisse = response.text();
      break;
  }
  return promisse.then(text => {
    let data = {};
    try {
      data = JSON.parse(text);
    } catch (e) {
      data.message = text;
    }

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (data.message === 'user.password.invalid') {
          data = 'Usuário não autorizado, verifique suas credenciais.';
        } else {
          logout();
        }
      }

      const error = data.message || data || response.statusText;
      return Promise.reject(error);
    }

    let token = response.headers.get('Authorization');

    localStorage.setItem('auth-token', token);
    return data;
  });
}
