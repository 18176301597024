import {institutionsConstants} from '../constants';
import {List} from 'immutable';

export function institutions(state = new List(), action) {
  switch (action.type) {
    case institutionsConstants.GETINSTITUTIONS_REQUEST:
      return Object.assign({}, state, {loading: true});

    case institutionsConstants.GETINSTITUTIONS_SUCCESS:
      return Object.assign({}, state, {loading: false, items: List(action.institutions)});

    case institutionsConstants.GETINSTITUTIONS_FAILURE:
      return Object.assign({}, state, {loading: false, error: action.error});

    case institutionsConstants.SELECT_INSTITUTION_REQUEST:
      return Object.assign({}, state, {loading: true});

    case institutionsConstants.SELECT_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {loading: false, selected: action.institution});

    case institutionsConstants.SELECT_INSTITUTION_FAILURE:
      return Object.assign({}, state, {loading: false, error: action.error});

    case institutionsConstants.INSTITUTION_NEW_LOGO:
      let logos = new List();
      if( state.logos ) {
        logos = new List(state.logos);
      }
      logos = logos.set(action.institution.id, action.logo);

      return Object.assign({}, state, {loading: false, logos});
    default:
      return state
  }
}

