import Api from '../helpers/api';

export const institutionService = {
  getInstitutions,
  selectInstitution,
  getInstitutionLogo
};

function getInstitutionLogo(id) {
  return Api.get(`/app/institutions/${id}/logo`)
}
function getInstitutions() {
    return Api.get(`/app/institutions`)
}

function selectInstitution(id) {
    return Api.get(`/web/refresh-token/institution/${id}`)
}
