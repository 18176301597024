let configs = {};

switch (process.env.REACT_APP_NODE_ENV) {
  default:
  case 'localhost':
    configs = {
      urlApi: 'http://192.168.1.120'
    };
    break;
  case 'development':
    configs = {
      urlApi: 'http://dev.axreg-server.anestech.com.br'
    };
    break;
  case 'production':
    configs = {
      urlApi: 'https://axreg-server.anestech.com.br'
    };
    break;
}

export default configs;
