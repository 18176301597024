import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { institutions } from './institution.reducer';
import { patient } from './patient.reducer';
import { selects } from './selects.reducer';
import { scheduling } from './scheduling.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  institutions,
  patient,
  selects,
  scheduling
});

export default rootReducer;
