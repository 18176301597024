import Map from "./components/pages/Map";
import Scheduling from "./components/pages/Scheduling";
import Patients from "./components/pages/Patients";
import Patient from "./components/pages/Patient";

export const pathRoutes = {
  login: '/login',
  logout: '/logout',
  home: '/',
  schedules: '/agendamentos',
  newSchedule: '/agendamentos/novo',
  editSchedule: '/agendamentos/editar/:id',
  patients: '/pacientes',
  patients_page: '/pacientes/:page/:filter?',
  newPatient: '/pacientes/novo',
  editPatient: '/pacientes/editar/:id'
};

export default {
  app: [
    {
      to: pathRoutes.editSchedule,
      component: Scheduling,
      exact: false,
    },
    {
      to: pathRoutes.newSchedule,
      component: Scheduling,
      exact: false,
    },
    {
      to: pathRoutes.schedules,
      component: Map,
      exact: false,
      menu: true,
      description: 'Mapa Cirúrgico'
    },
    {
      to: pathRoutes.newPatient,
      component: Patient,
      exact: false
    },
    {
      to: pathRoutes.editPatient,
      component: Patient,
      exact: false
    },
    {
      to: pathRoutes.patients_page,
      component: Patients,
      exact: false,
      menu: false,
    },
    {
      to: pathRoutes.patients,
      component: Patients,
      exact: false,
      menu: true,
      description: 'Pacientes'
    },
    {
      to: pathRoutes.home,
      component: Map,
      exact: true,
      menu: false,
      description: 'Mapa Cirúrgico'
    },
  ]
};
