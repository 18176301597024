import {selectsConstants} from '../constants';
import {List} from 'immutable';

export function selects(state = new List(), action) {
  switch (action.type) {
    case selectsConstants.GETETHNICGROUPS_REQUEST:
      return Object.assign({}, state, {
        ethnic_groups: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETETHNICGROUPS_SUCCESS:
      return Object.assign({}, state, {
        ethnic_groups: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETETHNICGROUPS_FAILURE:
      return Object.assign({}, state, {
        ethnic_groups: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGERYCENTERS_REQUEST:
      return Object.assign({}, state, {
        surgery_centers: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGERYCENTERS_SUCCESS:
      return Object.assign({}, state, {
        surgery_centers: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETSURGERYCENTERS_FAILURE:
      return Object.assign({}, state, {
        surgery_centers: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGERYCENTERROOMS_REQUEST:
      return Object.assign({}, state, {
        surgery_center_rooms: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGERYCENTERROOMS_SUCCESS:
      return Object.assign({}, state, {
        surgery_center_rooms: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETSURGERYCENTERROOMS_FAILURE:
      return Object.assign({}, state, {
        surgery_center_rooms: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETROOMS_REQUEST:
      return Object.assign({}, state, {
        rooms: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETROOMS_SUCCESS:
      return Object.assign({}, state, {
        rooms: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETROOMS_FAILURE:
      return Object.assign({}, state, {
        rooms: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETMEDICALPLAN_REQUEST:
      return Object.assign({}, state, {
        medical_plans: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETMEDICALPLAN_SUCCESS:
      return Object.assign({}, state, {
        medical_plans: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETMEDICALPLAN_FAILURE:
      return Object.assign({}, state, {
        medical_plans: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGICALPROCEDURESMOSTUSED_REQUEST:
      return Object.assign({}, state, {
        surgical_procedures_most_used: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGICALPROCEDURESMOSTUSED_SUCCESS:
      return Object.assign({}, state, {
        surgical_procedures_most_used: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETSURGICALPROCEDURESMOSTUSED_FAILURE:
      return Object.assign({}, state, {
        surgical_procedures_most_used: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGICALPROCEDURES_REQUEST:
      return Object.assign({}, state, {
        surgical_procedures: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGICALPROCEDURES_SUCCESS:
      return Object.assign({}, state, {
        surgical_procedures: {
          loading: false,
          data: action.data.result,
          count: action.data.count,
          page: action.data.page,
          perPage: action.data.perPage
        }
      });
    case selectsConstants.GETSURGICALPROCEDURES_FAILURE:
      return Object.assign({}, state, {
        surgical_procedures: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGEONS_FAILURE:
      return Object.assign({}, state, {
        surgeons: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETSURGEONS_REQUEST:
      return Object.assign({}, state, {
        surgeons: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGEONS_SUCCESS:
      return Object.assign({}, state, {
        surgeons: {
          loading: false,
          data: action.data.result,
          count: action.data.count,
          page: action.data.page,
          perPage: action.data.perPage
        }
      });
    case selectsConstants.GETANAESTHETISTS_FAILURE:
      return Object.assign({}, state, {
        anaesthetists: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    case selectsConstants.GETANAESTHETISTS_REQUEST:
      return Object.assign({}, state, {
        anaesthetists: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETANAESTHETISTS_SUCCESS:
      return Object.assign({}, state, {
        anaesthetists: {
          loading: false,
          data: action.data.result,
          count: action.data.count,
          page: action.data.page,
          perPage: action.data.perPage
        }
      });
    case selectsConstants.GETSURGERYTYPES_REQUEST:
      return Object.assign({}, state, {
        surgery_types: {
          loading: true,
          data: new List()
        }
      });
    case selectsConstants.GETSURGERYTYPES_SUCCESS:
      return Object.assign({}, state, {
        surgery_types: {
          loading: false,
          data: action.data
        }
      });
    case selectsConstants.GETSURGERYTYPES_FAILURE:
      return Object.assign({}, state, {
        surgery_types: {
          loading: false,
          data: new List(),
          error: action.error
        }
      });
    default:
      return state
  }
}
