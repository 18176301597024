import React, {Component} from 'react';
import moment from "moment";
import {DatePicker, Icon, Input} from "antd";

export default class DateTime extends Component {
  state = {
    valueDate: moment(),
    valueHour: moment().format('HH:mm')
  };

  constructor(props) {
    super(props);
    this.hourRef = React.createRef();
    this.dateRef = React.createRef();
  }

  componentDidMount() {
    const {value} = this.props;

    this.setValue(value);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value
      && typeof this.props.value.diff === "function"
      && this.props.value.diff(prevProps.value, 'minutes') !== 0) {
      this.setValue(this.props.value);
    }
  }

  formatHour = (hour) => {
    hour = hour.replace(/[^\d]+/g, '');
    hour = hour.length > 4 ? hour.substr(1, 4) : '0' + hour;
    return hour.length > 2 ? hour.substr(0, 2) + ':' + hour.substr(2, 2) : hour;
  };

  onChangeHour = (e) => {
    let valueHour = this.formatHour(e.target.value);
    let hour = valueHour.substr(0, 2);
    let minute = valueHour.substr(3, 2);
    if (hour < 24 && minute < 60) {
      this.setValue(this.state.valueDate, valueHour);
    }
  };

  changeDate = (e) => {
    this.setValue(e);
    this.hourRef.current.focus();
  };

  setValue = (value, valueHour) => {
    if (!value || !value.isValid()) {
      return;
    }

    if (valueHour) {
      value = value.startOf('day');
      value.add('hour', valueHour.substr(0, 2));
      value.add('minute', valueHour.substr(3, 2));
    }

    this.setState({valueDate: value, valueHour: value.format('HH:mm')});

    if (typeof (this.props.onChange) === 'function') {
      this.props.onChange(value);
    }
  };

  focus = () => {
    this.dateRef.current.picker.setState({open: true})
  };

  render() {
    const {valueDate, valueHour} = this.state;

    return (
      <Input.Group compact>
        <DatePicker
          format={'DD/MM/YYYY'}
          style={{width: '60%'}}
          showAction={['focus', 'click']}
          value={valueDate}
          onChange={this.changeDate}
          ref={this.dateRef}/>
        <Input
          onChange={this.onChangeHour}
          suffix={<Icon style={{color: "rgba(0,0,0,.25)"}} type="clock-circle"/>}
          style={{width: '40%'}}
          value={valueHour}
          ref={this.hourRef}
        />
      </Input.Group>
    )
  }

}
