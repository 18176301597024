import React, {Component} from 'react';

import {Button, Col, DatePicker, Form, Icon, Input, Layout, Modal, notification, Radio, Row, Select,} from 'antd';

import {connect} from "react-redux";
import {selectsActions} from "../../actions";
import MaskedInput from 'react-text-mask';
import {dates} from "../../helpers/dates";
import moment from "moment";
import {patientService} from "../../services";
import {pathRoutes} from "../../routes";
import {withRouter} from "react-router-dom";
import {stringsHelp} from "../../helpers";

const FormItem = Form.Item;
const {Content} = Layout;
const Option = Select.Option;

const confirm = Modal.confirm;

class Patient extends Component {
  state = {
    patient: {},
    age: '',
    loading: false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {location} = this.props;
        const {selected} = this.props.institutions;

        this.setState({loading: true});

        let patient = Object.assign({
          institution_id: selected.id
        }, this.state.patient, values);

        let promiseSave;
        if (patient.id > 0) {
          promiseSave = patientService.putPatient(patient).then(result => {
            if (result.id > 0) {
              this.setState({loading: false});

              notification.success({
                message: 'Paciente alterado com sucesso'
              });

              this.props.history.goBack();
            }
          })
        } else {
          promiseSave = patientService.postPatient(patient).then(result => {
            if (result.id > 0) {
              this.setState({loading: false});

              if (location.from === pathRoutes.newSchedule) {

                this.props.history.push({
                  pathname: pathRoutes.newSchedule,
                  query: {
                    patient: result
                  }
                });
                return;
              }

              confirm({
                cancelText: 'NÃO',
                okText: 'SIM',
                title: 'Paciente adicionado com sucesso',
                content: 'Deseja cadastrar um procedimento para o novo paciente?',
                onOk: () => {
                  this.props.history.push({
                    pathname: pathRoutes.newSchedule,
                    query: {
                      patient: result
                    }
                  });
                },
                onCancel: () => {
                  this.props.history.goBack();
                },
              });
            }
          });
        }
        promiseSave
          .catch(error => {
            console.log(error);
            notification.error({
              message: error
            });
          });
      }
    });
  };

  componentDidMount() {
    const {dispatch, match, history, location} = this.props;
    if (match.params.id) {
      this.setState({loading: true});
      patientService
        .getPatient(match.params.id)
        .then(patient => {
          patient = patient ? patient : {};

          if (!patient.id) {
            return null;
          }
          let age = '';
          if (patient.birth_date) {
            patient.birth_date = moment(patient.birth_date);
            age = dates.age(patient.birth_date);
          }
          let phones = stringsHelp.formatPhoneNumbers(patient.phone_number);

          patient.phone_number = phones[0] ? phones[0] : null;
          patient.phone_number_2 = phones[1] ? phones[1] : patient.phone_number_2;

          this.setState({patient, age, loading: false})
        })
        .catch(error => {
          console.log(error);
          notification.error({
            message: 'Não foi possível recuperar o paciente.'
          });
          history.goBack();
        });
    }

    if (location.query && location.query.name) {
      this.setState({patient: Object.assign({}, this.state.patient, {name: location.query.name})});
    }

    dispatch(selectsActions.getEthnicGroups());
  }


  validatorBirthDate = (rule, value, callback) => {
    if (value && moment().startOf('day').diff(value.startOf('day'), 'days') < 0) {
      callback(rule.message);
    }
    callback();
    return null;
  };

  validatorCPF = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
      return null;
    }

    let cpf = value.replace(/[^\d]+/g, '');

    const isRepeatingChars = (str) =>
      str.split('').every((elem) => elem === str[0]);

    if (cpf.length !== 11 || isRepeatingChars(cpf)) {
      callback(rule.message);
    }

    let add = 0;

    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9))) {
      callback(rule.message);
    }

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(10))) {
      callback(rule.message);
    }

    callback();
    return null;
  };

  calcAge = (value) => {
    let age = dates.age(value);

    this.setState({age});
    this.focusField('cpf');
  };

  onChangePhoneNumber = (a) => {
    a.target.value = stringsHelp.formatPhoneNumber(a.target.value);
  };

  focusField(name) {
    let field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({open: true});
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({open: true});
    } else if (field.inputElement) {
      field.inputElement.focus();
    } else {
      field.focus();
    }
  }

  blurField(name) {
    let field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({open: false});
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({open: false});
    } else if (field.inputElement) {
      field.inputElement.blur();
    } else {
      field.blur();
    }
  }

  render() {
    const {ethnic_groups, match} = this.props;
    const {getFieldDecorator} = this.props.form;

    let {patient, loading} = this.state;
    let isEdit = match.params.id;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24}
      },
      wrapperCol: {
        xs: {span: 24}
      },
      colon: false
    };

    const colLarge = {md: {span: 24}, xxl: {span: 12}};
    const colSmall = {md: {span: 12}, xl: {span: 8}, xxl: {span: 6}};
    const colXSmall = {xs: {span: 18}, md: {span: 12}, xl: {span: 8}, xxl: {span: 6}};
    const colXSmall2 = {xs: {span: 6}, md: {span: 12}, xl: {span: 8}, xxl: {span: 6}};


    return (
      <Content>
        <Form onSubmit={this.handleSubmit}>

          <Row>
            <Col xs={24} sm={0}>
              <Button type="primary" className="add-procedure" shape='circle' size='large'
                      htmlType={loading ? 'button' : 'submit'} loading={loading}>
                {loading ? '' : (<Icon type="check" fill='#ffffff'/>)}
              </Button>
            </Col>
            <Col xs={24} sm={20}>
              <h1 className="title">
                {isEdit ? 'Editar' : 'Novo'} paciente
              </h1>
            </Col>
            <Col xs={0} sm={4}>
              <Button type="primary" className="add-procedure" htmlType='submit' loading={loading}>SALVAR</Button>
            </Col>
          </Row>
          <Row gutter={28}>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="NOME COMPLETO"
              >
                {getFieldDecorator('name', {
                  rules: [{
                    required: true, message: 'Preencha o nome do paciente.',
                  }],
                  initialValue: patient.name,
                })(
                  <Input onChange={(e) => e.target.value = stringsHelp.firstLetterUpper(e.target.value, true)}/>
                )}
              </FormItem>
            </Col>
            <Col {...colXSmall}>
              <FormItem
                {...formItemLayout}
                label="DATA NASCIMENTO"
              >
                {getFieldDecorator('birth_date', {
                  initialValue: patient.birth_date ? patient.birth_date : null,
                  rules: [
                    {
                      required: true,
                      message: 'Informe a data de nascimento.'
                    },
                    {
                      validator: this.validatorBirthDate,
                      message: 'A data de nascimento não pode ser maior que hoje.'
                    }
                  ]
                })(
                  <DatePicker format={'DD/MM/YYYY'} style={{width: '100%'}} onChange={this.calcAge} onFocus={() => this.focusField('birth_date')} onBlur={() => this.blurField('birth_date')}/>
                )}
              </FormItem>
            </Col>
            <Col {...colXSmall2}>
              <FormItem
                {...formItemLayout}
                label="IDADE"
              >
                <span>{this.state.age ? this.state.age : '-'}</span>
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="CPF"
              >
                {getFieldDecorator('cpf', {
                  initialValue: patient.cpf, rules: [
                    {
                      validator: this.validatorCPF,
                      message: 'CPF informado inválido.',
                    }
                  ],
                })(
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    className='ant-input'/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="GÊNERO"
              >
                {getFieldDecorator('gender_id', {initialValue: patient.gender_id})(
                  <Radio.Group>
                    <Radio.Button value={1}>MASCULINO</Radio.Button>
                    <Radio.Button value={2}>FEMININO</Radio.Button>
                    <Radio.Button value={3}>OUTRO</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="GRUPO ÉTNICO"
              >
                {getFieldDecorator('ethnic_group_id', {initialValue: patient.ethnic_group_id})(
                  <Select loading={ethnic_groups ? ethnic_groups.loading : false} showAction={['focus', 'click']} onChange={() => this.focusField('phone_number')}>
                    {
                      !ethnic_groups ? null : ethnic_groups.data.map(ethnic_group => {
                        return (
                          <Option key={ethnic_group.id}
                                  value={ethnic_group.id}>{ethnic_group.ethnic_group_i18n[0].name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="TELEFONE(s)"
              >
                <Input.Group compact>
                    {getFieldDecorator('phone_number', {
                      initialValue: patient.phone_number,
                      onChange: this.onChangePhoneNumber
                    })(
                      <Input style={{width: '50%'}}/>
                    )}
                    {getFieldDecorator('phone_number_2', {
                      initialValue: patient.phone_number_2,
                      onChange: this.onChangePhoneNumber
                    })(
                      <Input style={{width: '50%'}}/>
                    )}
                </Input.Group>
              </FormItem>
            </Col>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="ALERGIAS"
              >
                {getFieldDecorator('allergies', {initialValue: patient.allergies})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="ENDEREÇO"
              >
                {getFieldDecorator('address', {initialValue: patient.address})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="EMAIL"
              >
                {getFieldDecorator('email', {initialValue: patient.email})(
                  <Input type={'email'}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const {selects, institutions} = state;
  const {ethnic_groups} = selects;
  return {
    ethnic_groups,
    institutions
  };
}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Patient)));
export default connected;
