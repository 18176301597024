import React, {Component} from 'react';
import {Button, Col, Icon, Modal} from "antd";


export default class CustomModal extends Component{


  handleCancel = (e) => {
    const onCancel = this.props.onCancel;
    if (onCancel) {
      onCancel(e);
    }
  };

  handleOk = (e) => {
    const onOk = this.props.onOk;
    if (onOk) {
      onOk(e);
    }
  };

  renderTitle = () => {
    const { title, buttonOkText, buttonOkType } = this.props;

    return (
      <div>
        <button className='modal-close' type='button' onClick={this.handleCancel}><i className='icon-close'/></button>
        <span>{title}</span>
        {buttonOkText ? <Col xs={0} sm={4} className={'btn-ok-modal'}><Button type={buttonOkType} htmlType='button' onClick={this.handleOk}>{buttonOkText}</Button></Col> : ''}
        {buttonOkText ?  <Col xs={2} sm={0} className={'btn-ok-modal'}><Button type={buttonOkType} htmlType='button' shape='circle' size='large' onClick={this.handleOk}><Icon type='edit'/></Button></Col> : ''}
      </div>
    );
  };

  render() {
    return (
      <Modal
        {...this.props}
        title={this.renderTitle()}
        bodyStyle={{paddingTop: '21px'}}
        width='701px'
        zIndex={1024}
        footer={false}>
        {this.props.children}
      </Modal>
    );
  }
}
