import React, {Component} from "react";
import {Avatar, Col, Divider, Dropdown, Menu, Row, Skeleton} from "antd";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {institutionActions} from "../../../actions";
import {pathRoutes} from "../../../routes";
import {stringsHelp} from "../../../helpers";
import './style.scss';

class InstitutionsDropdown extends Component {

  constructor(props){
    super(props);

    this.state =  {
      institutionDropdownArrow: 'icon-arrow-down'
    };
  }

  institutionArrowChange = (a) => {
    if (a){
      this.setState({institutionDropdownArrow: 'icon-arrow-up'})
    } else {
      this.setState({institutionDropdownArrow: 'icon-arrow-down'})
    }
  };


  selectInstitution = (institution) => {
    const {dispatch} = this.props;

    dispatch(institutionActions.selectInstitution(institution)).then(success => {
      this.props.history.go(0);
    });
  };

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(institutionActions.getInstitutions());
  }

  descUserType = (type) => {
    switch (type) {
      case 'AD':
        return 'Administrador';
      case 'AN':
        return 'Anestesiologista';
      case 'PH':
        return 'Pharma';
      case 'IN':
        return 'Instituição';
      default:
        return 'Usuário';
    }
  };

  render() {
    const {institutions, user} = this.props;

    const menuUser = (
      <Menu selectedKeys={institutions.selected ? [`${institutions.selected.id}`, '-1', '-2'] : ['-1', '-2']} className={'dropdown-institution-menu'}>
        <Menu.Item disabled={true} key='-1'>
          <div className="ant-list-item-meta">
            <div className="ant-list-item-meta-content">
              <h4 className="ant-list-item-meta-title user" style={{marginBottom: -5}}>
                {user.email}
              </h4>
              <div className="ant-list-item-meta-description">
                {this.descUserType(user.type)}
              </div>
            </div>
          </div>
          <Divider style={{margin: '5px 0',left: '-12px', width: 'calc(100% + 24px)'}}/>
        </Menu.Item>
        {
          institutions.items && institutions.items.size > 1  ?
            institutions.items.map((institution) => {
              return (
                <Menu.Item key={institution.id} onClick={() => this.selectInstitution(institution)}>
                  <div className="ant-list-item-meta">
                    <div className="ant-list-item-meta-avatar">
                      <Avatar shape="square"
                              src={institution.logo ? `https://s3-us-west-2.amazonaws.com/anestech-homolog-storage/institution_logo/${institution.logo}` : null}
                              className='institution-logo'>Logo</Avatar>
                    </div>
                    <div className="ant-list-item-meta-content">
                      <h4 className="ant-list-item-meta-title" style={{marginBottom: -5}}>{stringsHelp.firstLetterUpper(institution.name, true)}</h4>
                    </div>
                  </div>
                </Menu.Item>
              );
            }) : ''
        }
        <Menu.Item disabled={true} key='-2'>
          {institutions.items && institutions.items.size > 1 ? <Divider style={{margin: '5px 0',left: '-12px', width: 'calc(100% + 24px)'}}/> : ''}
          <div className="ant-list-item-meta">
            <div className="ant-list-item-meta-content">
              <h4 className="ant-list-item-meta-title logout">
                <a href={pathRoutes.logout}>Encerrar sessão</a>
              </h4>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <Skeleton className='institutions-skeleton'
                active
                loading={institutions.loading}
                paragraph={false}
                avatar={{shape: 'square', style: {verticalAlign: 'middle'}}} title={{width: 200}}>
        <Dropdown overlay={menuUser} trigger={['click']} onVisibleChange={this.institutionArrowChange}>
            <Row className='institution-selected'>
              <Col xs={24} xl={5} style={{width: '40px'}}>
                <Avatar shape="square"
                        src={institutions.selected ? `https://s3-us-west-2.amazonaws.com/anestech-homolog-storage/institution_logo/${institutions.selected.logo}` : null}
                        className='institution-logo'>Logo</Avatar>
              </Col>
              <Col xs={0} xl={17} style={{width: 'calc(100% - 66px)', marginLeft: '5px'}} className={'institution-user'}>
                <div>
                  <span style={{float: 'left'}}>{institutions.selected ? stringsHelp.firstLetterUpper(institutions.selected.name, true) : ''}</span>
                  <small style={{float: 'left'}}>{user.email}</small>
                </div>
              </Col>
              <Col xs={0} xl={2} style={{width: '21px'}}><i className={this.state.institutionDropdownArrow} style={{ color: '#7c8595' }}/></Col>
            </Row>
        </Dropdown>
      </Skeleton>

    );
  }
}


function mapStateToProps(state) {
  const { institutions, authentication } = state;
  const { user } = authentication;
  return {
    user,
    institutions
  };
}

const connected = withRouter(connect(mapStateToProps)(InstitutionsDropdown));
export default connected;
