import React, {Component} from 'react';

import {
  Layout,
  Row,
  List, Col, Form, Tooltip, Avatar, Icon
} from 'antd';
import Modal from '../Modal';
import {generatePath, Link} from "react-router-dom";
import {pathRoutes} from "../../routes";
import {connect} from "react-redux";
import {schedulingActions} from "../../actions";
import {dates, stringsHelp} from "../../helpers";
import moment from "moment";
import {CancelToken} from "axios";

const {Content} = Layout;

class Map extends Component {

  state = {
    modalVisible: false
  };

  cancelSource = CancelToken.source();

  loadMap = () => {
    const {dispatch, scheduling} = this.props;
    this.date = scheduling.date;

    if (scheduling.date) {

      if (scheduling.loading) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      dispatch(schedulingActions.getScheduling(scheduling.date, this.cancelSource.token));
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {scheduling} = this.props;

    if (scheduling.date !== this.date) {
      this.loadMap();
    }
  }

  componentDidMount() {
    this.loadMap();
  }

  openModal = (procedure) => {
    this.modalProcedure = procedure;

    this.setModalVisible(true);
  }

  setModalVisible = (modalVisible) => {
    this.setState({modalVisible});
  };

  handleOk = (id) => {
    this.props.history.push(generatePath(pathRoutes.editSchedule, {id}));
  };

  handleCancel = () => {
    this.setModalVisible(false);
  };


  render() {
    const {scheduling} = this.props;

    let modalProcedure = this.modalProcedure;

    let data = scheduling.data ? scheduling.data.map(procedure => {
      let date = moment(procedure.surgery_probable_date)
      let avatar = (procedure.user && procedure.user.anaesthetist) ?
        (<Avatar>{stringsHelp.avatar(procedure.user.anaesthetist.name)}</Avatar>)
        : '';
      let surgical_procedures = '';

      if (procedure.laterality_procedures.length) {
        surgical_procedures = stringsHelp.firstLetterUpper(procedure.laterality_procedures[0].surgical_procedure.name);
        if (procedure.laterality_procedures.length > 1) {
          surgical_procedures = (<span>{surgical_procedures} <label
            className="laterality">+{procedure.laterality_procedures.length - 1}</label></span>);
        }
      }

      let allergies = '';
      if (procedure.patient.allergies && procedure.patient.allergies !== '') {
        allergies = (
          <Tooltip title={procedure.patient.allergies}>
            <span className="allergic">Alérgico</span>
          </Tooltip>
        );
      }

      let hard_iot = '';
      if (procedure.selected_answers && procedure.selected_answers.length) {
        let question = procedure.selected_answers.filter(q => q.question_id === 106);
        if (question.length) {
          let answer = question[0].selected_question_answers.filter(a => a.answer_id === 354);

          if (answer.length) {
            hard_iot = (
              <Tooltip title={answer[0].other_text}>
                <span className="iot-hard">IOT Difícil</span>
              </Tooltip>
            )
          }
        }
      }

      return {
        extra: avatar,
        meta: {
          avatar: <span className='hour'>{date.format('HH:mm')}</span>,
          title: stringsHelp.firstLetterUpper(procedure.patient.name, true) + (procedure.patient.cpf ? ' | ' + procedure.patient.cpf : ''),
          description: (
            <div>
              {surgical_procedures}
              {allergies}
              {hard_iot}
            </div>),
        },
        procedure
      };
    }) : [];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <Link to={pathRoutes.newSchedule} className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure">
              <Icon type="plus" fill='#ffffff' />
              </Link>
          </Col>
          <Col xs={24} sm={20}>
            <h1 className="title">
              Procedimentos agendados
            </h1>
          </Col>
          <Col xs={0} sm={4}>
            <Link to={pathRoutes.newSchedule} className="add-procedure">ADICIONAR</Link>
          </Col>
        </Row>
        <Row>
          <List
            loading={scheduling.loading}
            itemLayout="horizontal"
            dataSource={data}
            size="large"
            renderItem={item => (
              <List.Item actions={[<span><i className="icon-more"/></span>]}
                         onClick={() => this.openModal(item.procedure)}>
                <List.Item.Meta {...item.meta}/>
                {item.extra}
              </List.Item>
            )}
            className="scheduling-list"
          />
        </Row>

        {modalProcedure ?
          <Modal
            title='Agendamento'
            visible={this.state.modalVisible}
            onCancel={this.handleCancel}
            onOk={() => modalProcedure.user_id > 1 ? null : this.handleOk(modalProcedure.id)}
            buttonOkText={modalProcedure.user_id > 1 ? false : 'EDITAR' }
            buttonOkType='secondary'>
            <Row>
              <Col span={24} className='modal-scheduling-view'>
                <Col span={24} className='modal-scheduling-view-title'>
                  <span>Paciente</span>
                </Col>
                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>NOME</span>
                  <span>{stringsHelp.firstLetterUpper(modalProcedure.patient.name, true)}</span>
                </Col>
                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>CPF</span>
                  <span>{modalProcedure.patient.cpf ? modalProcedure.patient.cpf : '-'}</span>
                </Col>
                <Col xs={24} sm={16} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>IDADE</span>
                  <span>
                    {modalProcedure.patient.birth_date ? dates.age(modalProcedure.patient.birth_date) : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={16} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>ALERGIAS</span>
                  <span>
                    {modalProcedure.patient.allergies ? modalProcedure.patient.allergies : '-'}
                  </span>
                </Col>
              </Col>
              <Col span={24} className='modal-scheduling-view'>
                <Col span={24} className='modal-scheduling-view-title'>
                  <span>Local</span>
                </Col>
                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>INSTITUIÇÃO</span>
                  <span>{stringsHelp.firstLetterUpper(modalProcedure.institution.name ? modalProcedure.institution.name : '-', true)}</span>
                </Col>
                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>CENTRO CIRÚRGICO</span>
                  <span>{modalProcedure.surgery_center ? modalProcedure.surgery_center.name : '-'}</span>
                </Col>
                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>SALA</span>
                  <span>{modalProcedure.surgery_center_room ? modalProcedure.surgery_center_room.name : '-'}</span>
                </Col>
                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>ACOMODAÇÃO</span>
                  <span>{modalProcedure.room ? modalProcedure.room.room_i18n[0].name : '-'}</span>
                </Col>
              </Col>
              <Col span={24} className='modal-scheduling-view'>
                <Col span={24} className='modal-scheduling-view-title'>
                  <span>Atendimento</span>
                </Col>

                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>Nº DE PRONTUÁRIO</span>
                  <span>{modalProcedure.medical_record_number ? modalProcedure.medical_record_number : '-'}</span>
                </Col>

                <Col xs={24} sm={16} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>Nº DE ATENDIMENTO</span>
                  <span>{modalProcedure.register_number ? modalProcedure.register_number : '-'}</span>
                </Col>

                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>CONVÊNIO</span>
                  <span>{modalProcedure.medical_plan.name ? modalProcedure.medical_plan.name : '-'}</span>
                </Col>

                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>MATRÍCULA CONVÊNIO</span>
                  <span>{modalProcedure.patient_medical_plan_code ? modalProcedure.patient_medical_plan_code : '-'}</span>
                </Col>

                <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>SENHA AUTORIZAÇÃO</span>
                  <span>{modalProcedure.patient_medical_plan_password ? modalProcedure.patient_medical_plan_password : '-'}</span>
                </Col>
              </Col>
              <Col span={24} className='modal-scheduling-view'>
                <Col span={24} className='modal-scheduling-view-title'>
                  <span>Cirurgias</span>
                </Col>

                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>TIPO DE CIRURGIA</span>
                  <span>{modalProcedure.surgery_type ? modalProcedure.surgery_type.surgery_type_i18n[0].name : '-'}</span>
                </Col>

                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>CIRURGIAS</span>
                  {
                    modalProcedure.laterality_procedures ? modalProcedure.laterality_procedures.map(laterality => {
                      let procedureName = stringsHelp.firstLetterUpper(laterality.surgical_procedure.name);
                      return (<span key={laterality.id}>{laterality.surgical_procedure.code} - {procedureName} - {laterality.laterality}</span>);
                    })
                      : '-'
                  }
                </Col>
              </Col>
              <Col span={24} className='modal-scheduling-view'>
                <Col span={24} className='modal-scheduling-view-title'>
                  <span>Equipe</span>
                </Col>
                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>CIRURGIÕES</span>
                  <span>{
                    modalProcedure.active_surgeons.length ? modalProcedure.active_surgeons.map(surgeon => {
                        return 'Dr(a). ' + stringsHelp.firstLetterUpper(surgeon.surgeon.name, true);
                      }).join(',')
                      : '-'
                  }</span>
                </Col>
                <Col span={24} className='modal-scheduling-view-item'>
                  <span className='modal-scheduling-view-item-title'>ANESTESIOLOGISTAS</span>
                  {
                    modalProcedure.active_anaesthetists.length ? modalProcedure.active_anaesthetists.map(anaesthetist => {
                      return (
                        <span key={anaesthetist.id}>
                          <Avatar style={{width: '30px', marginRight: '9px'}}>{stringsHelp.avatar(anaesthetist.anaesthetist.name)}</Avatar>
                          {'Dr(a). ' + stringsHelp.firstLetterUpper(anaesthetist.anaesthetist.name, true)}
                        </span>
                      );
                    })
                      : (<span>-</span>)
                  }
                </Col>
              </Col>
            </Row>
          </Modal>
          : ''
        }
      </Content>
    );
  }
}


function mapStateToProps(state) {
  const {scheduling} = state;
  return {
    scheduling,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Map));
export default connected;
