import React, {Component} from 'react';
import {CancelToken} from 'axios';

import {
  Layout,
  Row,
  List, Input, Col, Divider, Icon
} from 'antd';

import {generatePath, Link, withRouter} from "react-router-dom";
import {patientActions} from "../../actions";
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import Modal from "../Modal";
import {pathRoutes} from "../../routes";
import {stringsHelp} from "../../helpers";

const {Content} = Layout;

class Patients extends Component {

  state = {
    modalVisible: false,
    modalPatient: {},
    loading: false
  };

  filterValue = '';

  cancelSource = CancelToken.source();

  handleOk = (id) => {
    this.props.history.push(generatePath(pathRoutes.editPatient, {id}));
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  componentDidMount() {
    let page = this.props.match.params.page;
    let filter = this.props.match.params.filter;

    this.changePage(page ? page : 1, filter);
  }

  filterPatients = (e) => {
    this.changePage( 1, e.target.value);
  };

  changePage = (page, filter) => {
    this.setState({loading: true});
    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();
    let location;
    if(filter){
      location = generatePath(pathRoutes.patients_page, {page, filter});
    } else  {
      location = generatePath(pathRoutes.patients_page, {page});
    }
    this.props.history.push(location);

    const {dispatch} = this.props;

    this.page = page;
    this.filterValue = filter;

    dispatch(patientActions.getPatients(this.page, filter, this.cancelSource.token))
      .then(() => {
        this.setState({loading: false});
      });
  };

  openModal = (patient) => {
    this.setState({ modalVisible: true, modalPatient: patient });
  };

  render() {
    const {patient} = this.props;
    let data;

    if (patient.items) {
      data = patient.items.map((p => {
        return {
          meta: {
            title: `${stringsHelp.firstLetterUpper(p.name, true)} ${p.cpf ? '| ' + p.cpf : '' }`,
            description: p.last_procedure ?
              (
                `Último procedimento: ${moment(p.last_procedure.surgery_probable_date).format('DD/MM/YYYY')} | ${stringsHelp.firstLetterUpper(p.last_procedure.name)}`
              ) : 'Sem procedimentos',
          },
          patient: p
        }
      }));
    }
    let pagination =false;
    if(patient.total) {
      pagination = patient.total <= patient.perPage ? false :
        {
          total: patient.total,
          pageSize: patient.perPage,
          current: patient.page,
          onChange: (page) => this.changePage(page, this.filterValue)
        };
    }
    let modalPatient = this.state.modalPatient;
    return (
      <Content>
        <Row>

          <Col xs={24} sm={0} style={{marginBottom: '5px'}}>
            <Link to={pathRoutes.newPatient} className="ant-btn ant-btn-primary ant-btn-circle ant-btn-lg add-procedure"><Icon type="plus" fill='#ffffff' /></Link>
          </Col>
          <Col xs={24} sm={9} lg={14}>
            <h1 className="title">
              Pacientes
            </h1>
          </Col>

          <Col xs={24} sm={15} lg={10}>
            <Col xs={24} sm={14} md={16} lg={15} xxl={18}>
            <Input size='large' placeholder='Buscar'
                   suffix={(<i style={{color: '#1bbfbb'}} className='icon-search'/>)} ref={input => this.filter = input} onChange={this.filterPatients} value={this.filterValue}/>
            </Col>
            <Col xs={0} sm={10} md={8} lg={9} xxl={6}>
              <Link to={pathRoutes.newPatient} className="add-procedure">ADICIONAR</Link>
            </Col>
          </Col>
        </Row>
        <Row>
            <List
              loading={this.state.loading}
              itemLayout="horizontal"
              dataSource={data}
              size="large"
              renderItem={item => (
                  <List.Item onClick={() => this.openModal(item.patient)} actions={[<span><i className="icon-more"/></span>]}>
                      <List.Item.Meta {...item.meta}/>
                  </List.Item>
              )}
              className="scheduling-list"
              pagination={pagination}
            />
        </Row>

        <Modal
          title='Paciente'
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={() => this.handleOk(modalPatient.id)}
          buttonOkText='EDITAR'
          buttonOkType='secondary'>
          <Row>
            <Col span={24} className='modal-scheduling-view'>
              <Col span={24} className='modal-scheduling-view-title'>
                <span>Dados</span>
              </Col>
              <Col span={24} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>NOME</span>
                <span>{stringsHelp.firstLetterUpper(modalPatient.name ? modalPatient.name : '-', true)}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>NASCIMENTO</span>
                <span>{(new Date(modalPatient.birth_date)).toLocaleDateString()}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>IDADE</span>
                <span>{
                  Math.abs((new Date(Date.now() - new Date(modalPatient.birth_date))).getUTCFullYear() - 1970)
                }</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>CPF</span>
                <span>{modalPatient.cpf ? modalPatient.cpf : '-'}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>GÊNERO</span>
                <span>{modalPatient.gender ? modalPatient.gender.gender_i18n[0].name : '-'}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>GRUPO ÉTNICO</span>
                <span>{modalPatient.ethnic_group ? modalPatient.ethnic_group.ethnic_group_i18n[0].name : '-'}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>TELEFONE</span>
                <span>{
                  (modalPatient.phone_number || modalPatient.phone_number_2) ?
                    stringsHelp.formatPhoneNumbers(modalPatient.phone_number + '/' + modalPatient.phone_number_2).join(' / ')
                    : '-'}</span>
              </Col>
              <Col xs={24} sm={8} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>ESTADO CIVIL</span>
                <span>{modalPatient.marital_status ? modalPatient.marital_status.marital_status_i18n[0].name : '-'}</span>
              </Col>
              <Col xs={24} sm={16} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>ALERGIAS</span>
                <span>{modalPatient.allergies ? modalPatient.allergies : '-'}</span>
              </Col>
              <Col xs={24} sm={16} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>EMAIL</span>
                <span>{modalPatient.email ? modalPatient.email : '-'}</span>
              </Col>
              <Col span={24} className='modal-scheduling-view-item'>
                <span className='modal-scheduling-view-item-title'>ENDEREÇO</span>
                <span>{modalPatient.address ? modalPatient.address : '-'}</span>
              </Col>
            </Col>
            <Divider/>
            <Col span={24} className='modal-scheduling-view'>
              <Col span={24} className='modal-scheduling-view-title'>
                <span style={{verticalAlign: 'sub'}}>Procedimentos</span>
                <Link to={{pathname: pathRoutes.newSchedule, query: {
                  patient: modalPatient
                  }}} style={{float: 'right'}} className='ant-btn ant-btn-primary'>ADICIONAR NOVO</Link>
              </Col>
              <Col span={24} className='modal-scheduling-view-item modal-procedures-list'>
                <List
                  itemLayout="horizontal"
                  dataSource={modalPatient.procedures}
                  size="large"
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={(<Link to={generatePath(pathRoutes.editSchedule, {id: item.id})}>{item.name}</Link>)}
                        description={`Realizado em: ${(new Date(item.surgery_probable_date)).toLocaleDateString()}`}/>
                    </List.Item>
                  )}
                  className="scheduling-list"
                />
              </Col>
            </Col>
          </Row>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const {institutions, patient} = state;
  return {
    patient,
    institutions
  };
}

const connected = withRouter(connect(mapStateToProps)(Patients));
export default connected;
