import {schedulingConstants} from '../constants';
import {schedulingService} from "../services";

export const schedulingActions = {
  getScheduling,
  getProcedure,
  setDate
};

function getScheduling(date, cancelToken) {
  return dispatch => {
    dispatch(request());

    return schedulingService.getScheduling(date, cancelToken)
      .then(scheduling => {
        dispatch(success(scheduling));
        return scheduling;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: schedulingConstants.GETSCHEDULING_REQUEST}
  }

  function success(data) {
    return {type: schedulingConstants.GETSCHEDULING_SUCCESS, data}
  }

  function failure(error) {
    return {type: schedulingConstants.GETSCHEDULING_FAILURE, error}
  }
}

function getProcedure(id, cancelToken) {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(request());

    return schedulingService
      .getProcedure(id, cancelToken)
      .then(procedure => {
        dispatch(success(procedure));
        resolve(procedure);
      }, error => {
        dispatch(failure(error));
        reject(error);
      });
  });

  function request() {
    return {type: schedulingConstants.GETPROCEDURE_REQUEST}
  }

  function success(data) {
    return {type: schedulingConstants.GETPROCEDURE_SUCCESS, data}
  }

  function failure(error) {
    return {type: schedulingConstants.GETPROCEDURE_FAILURE, error}
  }
}


function setDate(date) {
  localStorage.setItem('filter-date', date);

  return dispatch => {
    dispatch({type: schedulingConstants.SETDATE, date});
  }
}
