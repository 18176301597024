import Api from '../helpers/api';
import uuid from 'uuid/v4';

export const schedulingService = {
  getScheduling,
  postProcedure,
  getProcedure,
  putProcedure
};

function getScheduling(date, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/procedures/map/${date.format('YYYY-MM-DD HH:mm:ssZ')}`, {cancelToken})
}

function getProcedure(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/procedures/${id}`, {cancelToken})
}

function postProcedure(procedure){
    procedure.uuid = uuid();
  return Api.post(`/app/procedures`, procedure)
}


function putProcedure(procedure){
  return Api.put(`/app/procedures/${procedure.id}`, procedure)
}
