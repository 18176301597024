import {institutionsConstants} from '../constants';
import {institutionService} from "../services";

export const institutionActions = {
  getInstitutions,
  selectInstitution
};

function getInstitutions() {
  return dispatch => {
    dispatch(request());

    return institutionService.getInstitutions()
      .then(institutions => {
        dispatch(success(institutions));
        let selected;
        try {
          selected = JSON.parse(localStorage.getItem('selected-institution'));
          let user = JSON.parse(localStorage.getItem('user'));

          if(user) {
            selected = user.institutions.filter(institution => institution.id === selected.id).pop()
          }
        } catch (e) {
        }
        if (!selected && institutions.length) {
          selected = institutions[0];
        }

        return dispatch(selectInstitution(selected)).then(() => {
          return institutions;
        });
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: institutionsConstants.GETINSTITUTIONS_REQUEST}
  }

  function success(institutions) {
    return {type: institutionsConstants.GETINSTITUTIONS_SUCCESS, institutions}
  }

  function failure(error) {
    return {type: institutionsConstants.GETINSTITUTIONS_FAILURE, error}
  }
}

function selectInstitution(institution) {
  return dispatch => {
    dispatch(request());

    return institutionService.selectInstitution(institution.id)
      .then(
        () => {
          localStorage.setItem('selected-institution', JSON.stringify(institution));
          dispatch(success(institution));
          return true;
        },
        error => dispatch(failure(error))
      );
  };


  function request() {
    return {type: institutionsConstants.SELECT_INSTITUTION_REQUEST}
  }

  function success(institution) {
    return {type: institutionsConstants.SELECT_INSTITUTION_SUCCESS, institution}
  }

  function failure(error) {
    return {type: institutionsConstants.SELECT_INSTITUTION_FAILURE, error}
  }
}
