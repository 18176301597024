import React, {Component} from 'react';

import {
  Form, Input, Select, Row, Col, Button, Layout, DatePicker, notification, Icon,
} from 'antd';

import {connect} from "react-redux";
import {schedulingActions, selectsActions} from "../../actions";
import AutocompletePatient from "../AutocompletePatient";
import SelectSurgery from "../SelectSurgery";
import SelectSurgeons from "../SelectSurgeons";
import SelectAnaesthetists from "../SelectAnaesthetists";
import {schedulingService} from "../../services/scheduling.service";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {stringsHelp} from "../../helpers";
import {pathRoutes} from "../../routes";
import DateTime from '../DateTime';

const FormItem = Form.Item;
const {Content} = Layout;
const Option = Select.Option;



class Scheduling extends Component {

  state = {
    procedure: {},
    saving: false
  };

  componentDidMount() {
    const {dispatch, match, history, location} = this.props;
    if (match.params.id) {
      dispatch(schedulingActions.getProcedure(match.params.id))
        .then(procedure => {
          procedure = procedure ? procedure : {};

          if (!procedure.id) {
            return null;
          }

          if (procedure.user_id !== 1) {
            notification.warning({
              message: 'Este procedimento não pode ser alterado.'
            });
            history.goBack();
            return null;
          }

          procedure.surgery_probable_date = procedure.surgery_probable_date ? moment(procedure.surgery_probable_date) : moment();
          procedure.surgical_procedures = procedure.laterality_procedures ? procedure.laterality_procedures.map(laterality => {
            let procedure = laterality.surgical_procedure;
            procedure.laterality = parseInt(laterality.laterality);

            return procedure;
          }) : [];

          procedure.surgeons = procedure.active_surgeons ? procedure.active_surgeons.map(active_surgeon => {
            return active_surgeon.surgeon;
          }) : [];

          procedure.anaesthetists = procedure.active_anaesthetists ? procedure.active_anaesthetists.map(active_anaesthetist => {
            return active_anaesthetist.anaesthetist;
          }) : [];

          this.loadSurgeryCenterRooms(procedure.surgery_center_id);
          this.setState({procedure})
        })
        .catch(error => {
          console.log(error);
          notification.error({
            message: 'Não foi possível recuperar o procedimento.'
          });
          history.goBack();
        });
    } else if (location.query && location.query.patient) {
      let procedure = localStorage.getItem('new-procedure');

      if (procedure) {
        try {
          procedure = JSON.parse(procedure);

          this.loadSurgeryCenterRooms(procedure.surgery_center_id);

          this.setState({procedure: Object.assign({}, procedure, {patient: location.query.patient})});
        } catch (error) {

        }
      }
    } else {
      let procedure = localStorage.getItem('new-procedure');

      if (procedure) {
        try {
          procedure = JSON.parse(procedure);

          this.loadSurgeryCenterRooms(procedure.surgery_center_id);
          this.setState({procedure});
        } catch (error) {

        }
      }
    }

    dispatch(selectsActions.getSurgeryCenters());
    dispatch(selectsActions.getMedicalPlans());
    dispatch(selectsActions.getRooms());
    dispatch(selectsActions.getSurgeryTypes());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({saving: true});

        const {selected} = this.props.institutions;


        values.active_surgeons = values.active_surgeons ? values.active_surgeons.map(surgeon => {
          return {surgeon_id: surgeon.id}
        }).toArray() : [];

        values.active_anaesthetists = values.active_anaesthetists ? values.active_anaesthetists.map(anaesthist => {
          return {anaesthetist_id: anaesthist.id}
        }).toArray() : [];

        values.laterality_procedures = values.surgical_procedures ? values.surgical_procedures.map(surgical_procedure => {
          return {
            laterality: surgical_procedure.laterality + 'x',
            surgical_procedure_id: surgical_procedure.id
          }
        }).toArray() : [];

        values.surgery_probable_date = values.surgery_probable_date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        values.name = values.surgical_procedures.get(0).code + '-' + stringsHelp.firstLetterUpper(values.surgical_procedures.get(0).name) + (values.surgical_procedures.size > 1 ? values.surgical_procedures.size - 1 : '');

        delete values.surgical_procedures;

        let procedure = Object.assign({
          user_id: 1,
          laterality_procedures_performed: [],
          surgical_procedures_performed_ids: [],
          surgical_procedures_planned_ids: [],
          tag_ids: [],
          techniques: [],
          date: moment(),
          institution_id: selected.id
        }, this.state.procedure, values);

        delete procedure.patient;

        let promiseSave;
        if (procedure.id > 0) {
          promiseSave = schedulingService.putProcedure(procedure).then(result => {
            if (result.id > 0) {
              this.setState({saving: false});

              notification.success({
                message: 'Porcedimento alterado com sucesso'
              });

              this.props.history.goBack();
            }
          })
        } else {
          promiseSave = schedulingService.postProcedure(procedure).then(result => {
            if (result.id > 0) {
              this.setState({saving: false});

              localStorage.setItem('new-procedure', 'added');

              notification.success({
                message: 'Porcedimento adicionado com sucesso'
              });

              this.props.history.push(pathRoutes.schedules);
            }
          });
        }
        promiseSave
          .catch(error => {
            console.log(error);
            notification.error({
              message: error
            });

          });
      }
    });
  };

  loadSurgeryCenterRooms = (surgery_center_id) => {
    const {dispatch} = this.props;

    let procedure = Object.assign({}, this.state.procedure, {surgery_center_room_id: null});

    this.setState({procedure});

    dispatch(selectsActions.getSurgeryCenterRooms(surgery_center_id));
  };

  selectMedicalPlan = (value) => {
    const {medical_plans} = this.props.selects;

    this.medical_plan = medical_plans.data.find(plan => plan.id === value);

    this.focusField('patient_medical_plan_code');
  };

  validatorModalSelect = (rule, value, callback) => {
    if (value && value.size > 0) {
      callback();
      return;
    }
    callback(rule.message);
  };

  newPatientRedirect = (value) => {
    const {history} = this.props;
    history.push({pathname: pathRoutes.newPatient, query: {name: value}, from: pathRoutes.newSchedule})
  };

  componentWillUnmount() {
    const {form} = this.props;

    let values = form.getFieldsValue();

    values.surgeons = values.active_surgeons ? values.active_surgeons.map(active_surgeon => {
      return active_surgeon;
    }) : [];

    values.anaesthetists = values.active_anaesthetists ? values.active_anaesthetists.map(active_anaesthetist => {
      return active_anaesthetist;
    }) : [];

    if (localStorage.getItem('new-procedure') === 'added') {
      localStorage.removeItem('new-procedure')
    } else {
      localStorage.setItem('new-procedure', JSON.stringify(values));
    }
  }

  selectPatient = (patient) => {
    patient = patient ? patient : {};
    this.setState({procedure: Object.assign({}, this.state.procedure, {patient, patient_uuid: patient.uuid})});

    this.focusField('surgery_center_id')
  };


  validateHour = (rule, value, callback) => {
    let hour = value.substr(0, 2);
    let minute = value.substr(3, 2);

    if (hour && minute && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
      callback();
      return;
    }
    callback(rule.message);
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const propProcedure = this.props.procedure;
    const {match, institutions} = this.props;
    const {surgery_centers, surgery_center_rooms, rooms, medical_plans, surgery_types} = this.props.selects;

    let procedure = this.state.procedure;
    let isEdit = match.params.id;

    let loadingForm = isEdit && propProcedure ? propProcedure.loading : false;
    loadingForm = institutions.selected ? loadingForm : true;
    loadingForm = this.state.saving ? true : loadingForm;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24}
      },
      wrapperCol: {
        xs: {span: 24}
      },
      colon: false
    };

    const colLarge = {lg: {span: 24}, xl: {span: 16}, xxl: {span: 12}};
    const colSmall = {lg: {span: 12}, xl: {span: 8}, xxl: {span: 6}};

    if (!this.medical_plan) {
      this.medical_plan = medical_plans ? medical_plans.data.find(plan => plan.id === procedure.medical_plan_id) : null;
    }

    return (
      <Content>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} sm={0}>
              <Button type="primary" className="add-procedure" shape='circle' size='large'
                      htmlType={loadingForm ? 'button' : 'submit'} loading={loadingForm}>
                {loadingForm ? '' : (<Icon type="check" fill='#ffffff'/>)}
              </Button>
            </Col>
            <Col xs={24} sm={20}>
              <h1 className="title">
                {isEdit ? 'Editar' : 'Novo'} agendamento
              </h1>
            </Col>
            <Col xs={0} sm={4}>
              <Button type="primary" className="add-procedure" htmlType={loadingForm ? 'button' : 'submit'}
                      loading={loadingForm}>SALVAR</Button>
            </Col>
          </Row>
          <Row gutter={28}>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="PACIENTE"
              >
                {getFieldDecorator('patient_id', {
                  rules: [{
                    required: true, message: 'Selecione um paciente',
                  }],
                  initialValue: procedure.patient ? procedure.patient.id : ''
                })(
                  <AutocompletePatient selectedPatient={procedure.patient} onNewPatient={this.newPatientRedirect}
                                       onSelectPatient={this.selectPatient}/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="CENTRO CIRÚRGICO"
              >
                {getFieldDecorator('surgery_center_id', {initialValue: procedure.surgery_center_id})(
                  <Select onChange={this.loadSurgeryCenterRooms}
                          loading={surgery_centers ? surgery_centers.loading : true}
                          showAction={['focus','click']}
                          onSelect={() => this.focusField('surgery_center_room_id')}>
                    {
                      !surgery_centers ? null : surgery_centers.data.map(surgery_center => {
                        return (
                          <Option key={surgery_center.id}
                                  value={surgery_center.id}>{surgery_center.name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="SALA"
              >
                {getFieldDecorator('surgery_center_room_id', {initialValue: procedure.surgery_center_room_id})(
                  <Select loading={surgery_center_rooms ? surgery_center_rooms.loading : true}
                          notFoundContent='Selecione um Centro cirúrgico' showAction={['focus','click']}
                          onSelect={() => this.focusField('room_id')}>
                    {
                      !surgery_center_rooms ? null : surgery_center_rooms.data.map(surgery_center_room => {
                        return (
                          <Option key={surgery_center_room.id}
                                  value={surgery_center_room.id}>{surgery_center_room.name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="ACOMODAÇÕES"
              >
                {getFieldDecorator('room_id', {initialValue: procedure.room_id})(
                  <Select loading={rooms ? rooms.loading : true} showAction={['focus','click']}
                          onSelect={() => this.focusField('surgery_probable_date')}>
                    {
                      !rooms ? null : rooms.data.map(room => {
                        return (
                          <Option key={room.id}
                                  value={room.id}>{room.room_i18n[0].name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
						<Col {...colSmall}>
							<FormItem
								{...formItemLayout}
								label="DATA PROVÁVEL DE CIRURGIA"
							>
									{getFieldDecorator('surgery_probable_date', {
										rules: [{
											required: true,
											message: 'Preencha a data provável da cirurgia'
										}],
										initialValue: procedure.surgery_probable_date,
										onChange: () => this.focusField('surgery_probable_date_hour')
									})(
									  <DateTime/>
                    )}
							</FormItem>
						</Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="Nº DE PRONTUÁRIO"
              >
                {getFieldDecorator('medical_record_number', {initialValue: procedure.medical_record_number})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="Nº DE ATENDIMENTO"
              >
                {getFieldDecorator('register_number', {initialValue: procedure.register_number})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="CONVÊNIO"
              >
                {getFieldDecorator('medical_plan_id', {
                  rules: [{
                    required: true, message: 'Selecione um convênio',
                  }],
                  initialValue: procedure.medical_plan_id
                })(
                  <Select onSelect={this.selectMedicalPlan}
                          loading={medical_plans ? medical_plans.loading : true}
                          showAction={['focus','click']}
                          >
                    {
                      !medical_plans ? null : medical_plans.data.map(medical_plan => {
                        return (
                          <Option key={medical_plan.id}
                                  value={medical_plan.id}>{medical_plan.name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="MATRÍCULA DO CONVÊNIO"
              >
                {getFieldDecorator('patient_medical_plan_code', {initialValue: procedure.patient_medical_plan_code})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="SENHA DE AUTORIZAÇÃO DO CONVÊNIO"
              >
                {getFieldDecorator('patient_medical_plan_password', {initialValue: procedure.patient_medical_plan_password})(
                  <Input/>
                )}
              </FormItem>
            </Col>
            <Col {...colSmall}>
              <FormItem
                {...formItemLayout}
                label="TIPO DE CIRURGIA"
              >
                {getFieldDecorator('surgery_type_id', {
                  rules: [{
                    required: true, message: 'Selecione um tipo de cirurgia',
                  }],
                  initialValue: procedure.surgery_type_id
                })(
                  <Select loading={surgery_types ? surgery_types.loading : true} showAction={['focus','click']}>
                    {
                      !surgery_types ? null : surgery_types.data.map(surgery_type => {
                        return (
                          <Option key={surgery_type.id}
                                  value={surgery_type.id}>{surgery_type.surgery_type_i18n[0].name}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="CIRURGIAS PROPOSTAS"
              >
                {this.medical_plan ? getFieldDecorator('surgical_procedures', {
                  rules: [{
                    required: true,
                    validator: this.validatorModalSelect,
                    message: 'Adicione ao menos uma cirurgia',
                  }],
                  initialValue: procedure.surgical_procedures
                })(
                  <SelectSurgery medical_plan_table_id={this.medical_plan.medical_plan_table_id}/>
                ) : 'Selecione um convênio'}
              </FormItem>
            </Col>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="CIRURGIÕES"
              >
                {getFieldDecorator('active_surgeons', {initialValue: procedure.surgeons})(
                  <SelectSurgeons/>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={28}>
            <Col {...colLarge}>
              <FormItem
                {...formItemLayout}
                label="ANESTESIOLOGISTAS"
              >
                {getFieldDecorator('active_anaesthetists', {
                  initialValue: procedure.anaesthetists
                })(
                  <SelectAnaesthetists/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Content>
    );
  }

  focusField(name) {
    let field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({open: true});
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({open: true});
    } else {
      field.focus();
    }
  }
}


function mapStateToProps(state) {
  const {selects, institutions, scheduling} = state;
  const {procedure} = scheduling;
  return {
    selects,
    institutions,
    procedure
  };
}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Scheduling)));
export default connected;
