import {Component} from 'react';
import {pathRoutes} from '../../routes';
import {withRouter} from "react-router-dom";

class Logout extends Component {
  constructor(props){
    super(props);

    localStorage.removeItem('auth-token');
    localStorage.removeItem('user');

    this.props.history.push(pathRoutes.login);
  }

  render() {
    return null;
  }
}

export default withRouter(Logout);