import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {pathRoutes} from "../../routes";
import {Animated} from "react-animated-css";

export const PrivateRoute = ({component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ? <Animated animationIn={'fadeIn'} style={{flexGrow: 1}}><Component {...rest} {...props} /></Animated>
      : <Redirect to={{ pathname: pathRoutes.login, state: { from: props.location } }} />
  )} />
);
